/**
 * 获取支付码信息 工具类
 *
 * @author terrfly
 * @site https://www.jeepay.vip
 * @date 2021/5/8 07:18
 */
const getMchName = function () {
    return localStorage.getItem("mchName")
}

const setMchName = function (mchName) {
    localStorage.setItem("mchName", mchName)
}

export default {
    getMchName: getMchName,
    setMchName: setMchName
}
